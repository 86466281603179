import React from 'react'
import { Outlet } from 'react-router-dom'
import Header from './Header'
import Footer from './Footer'

export default function Layout() {
	return (
		<div>
			<div className="">
				<Header />
				<main className="">
					<Outlet />
					<Footer />
				</main>
				
			</div>
		</div>
	)
}