const sliderMedia = ["https://res.cloudinary.com/dvesljkuv/image/upload/v1656390536/bullo_galeria_75d6e75c04.jpg",
"https://res.cloudinary.com/dvesljkuv/image/upload/v1669145467/IMG_0461_cf981c8c83.jpg",
"https://res.cloudinary.com/dvesljkuv/image/upload/v1669145467/IMG_0463_a9f34949c4.jpg",
"https://res.cloudinary.com/dvesljkuv/image/upload/v1669145467/IMG_0312_57b8a1526b.jpg",
"https://res.cloudinary.com/dvesljkuv/image/upload/v1669145468/IMG_1201_a2d1e1caf0.jpg",
"https://res.cloudinary.com/dvesljkuv/image/upload/v1669145475/IMG_1750_a1bc9a089d.jpg",
"https://res.cloudinary.com/dvesljkuv/image/upload/v1669145507/DSCN_5109_fb5543684a.jpg",
"https://res.cloudinary.com/dvesljkuv/image/upload/v1669145508/IMG_0654_9f3dcf1310.jpg",
"https://res.cloudinary.com/dvesljkuv/image/upload/v1669145467/IMG_0463_a9f34949c4.jpg",
"https://res.cloudinary.com/dvesljkuv/image/upload/v1669145507/Negative0_15_23_A_1_50781e52ce.jpg",
"https://res.cloudinary.com/dvesljkuv/image/upload/v1656390566/bullo1_e28df5b51d.jpg",
"https://res.cloudinary.com/dvesljkuv/image/upload/v1656435744/IMG_2951_6de6a1d739.jpg",
"https://res.cloudinary.com/dvesljkuv/image/upload/v1656435744/IMG_2969_e829aa6da2.jpg",
"https://res.cloudinary.com/dvesljkuv/image/upload/v1656435743/IMG_3172_ce8e9e4248.jpg",
"https://res.cloudinary.com/dvesljkuv/image/upload/v1656435743/IMG_3134_1e18ea481a.jpg",
"https://res.cloudinary.com/dvesljkuv/image/upload/v1656435742/IMG_3159_ad16146b48.jpg",
"https://res.cloudinary.com/dvesljkuv/image/upload/v1656435742/IMG_3065_9bbf81bccc.jpg",
"https://res.cloudinary.com/dvesljkuv/image/upload/v1669145508/IMG_0821_6bf53a3364.jpg",
"https://res.cloudinary.com/dvesljkuv/image/upload/v1669145513/IMG_0613_c0275b1ae0.jpg",
];

export default sliderMedia;
